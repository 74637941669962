import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import SignIn from './components/SignIn';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import Reports from './components/Reports';
import Profiles from './components/Profiles';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return sessionStorage.getItem('isAuthenticated') === 'true';
  });

  const [username, setUsername] = useState(() => {
    return sessionStorage.getItem('username') || '';
  });

  const handleSignIn = (user) => {
    setIsAuthenticated(true);
    setUsername(user);
    sessionStorage.setItem('isAuthenticated', 'true');
    sessionStorage.setItem('username', user); // Save username to session storage
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('isAuthenticated');
      sessionStorage.removeItem('username');
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignIn onSignIn={handleSignIn} />} />
        <Route path="/home" element={isAuthenticated ? <HomeWithHeaderFooter username={username} /> : <Navigate to="/" replace />} />
        <Route path="/dashboard" element={isAuthenticated ? <DashboardWithHeaderFooter username={username} /> : <Navigate to="/" replace />} />
        <Route path="/reports" element={isAuthenticated ? <ReportsWithHeaderFooter username={username} /> : <Navigate to="/" replace />} />
        <Route path="/profiles" element={isAuthenticated ? <ProfilesWithHeaderFooter username={username} /> : <Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

const HomeWithHeaderFooter = ({ username }) => (
  <>
    <Header username={username} />
    <Home />
    <Footer />
  </>
);

const DashboardWithHeaderFooter = ({ username }) => (
  <>
    <Header username={username} />
    <Dashboard />
    <Footer />
  </>
);

const ReportsWithHeaderFooter = ({ username }) => (
  <>
    <Header username={username} />
    <Reports />
    <Footer />
  </>
);

const ProfilesWithHeaderFooter = ({ username }) => (
  <>
    <Header username={username} />
    <Profiles />
    <Footer />
  </>
);

export default App;

import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import Modal from './Modal';

const exportToExcel = () => {
  const table = document.getElementById('attendance-table');
  const ws = XLSX.utils.table_to_sheet(table);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Attendance');
  XLSX.writeFile(wb, 'attendance.xlsx');
};

function exportToPDF() {
  const doc = new jsPDF();
  doc.autoTable({ html: '#attendance-table' });
  doc.save('attendance.pdf');
}

const exportReportToExcel = () => {
  const table = document.querySelector('.report-table');
  const ws = XLSX.utils.table_to_sheet(table);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Attendance');
  XLSX.writeFile(wb, 'attendance.xlsx');
};

function exportReportToPDF() {
  const doc = new jsPDF();
  doc.autoTable({ html: '.report-table' });
  doc.save('attendance.pdf');
}

function formatDate(selectedDate) {
  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  return new Date(selectedDate).toLocaleDateString('en-US', options);
}

function formatTime(datetime, isTimeout = false, timeInDate = null) {
  if (!datetime) {
    return '-';
  }

  const date = new Date(datetime);
  if (isNaN(date.getTime())) {
    return '-';
  }

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;

  const timeFormatted = `${hours}:${minutesStr} ${ampm}`;

  if (isTimeout && timeInDate) {
    const timeInDateOnly = new Date(timeInDate).toDateString();
    const timeOutDateOnly = date.toDateString();

    if (timeInDateOnly !== timeOutDateOnly) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}, ${timeFormatted}`;
    }
  }

  return timeFormatted;
}



function formatDuration(duration) {
  if (!duration) {
    return '-';
  }

  if (duration === '00:00:00') {
    return '0 minutes';
  }

  // Split duration into hours, minutes, and seconds
  const [hours, minutes, seconds] = duration.split(':').map(Number);

  // Convert days into hours (e.g., 1 day = 24 hours)
  const totalHours = hours;
  let hoursStr = '';
  let minutesStr = '';

  if (totalHours > 0) {
    hoursStr = totalHours === 1 ? '1 hour' : `${totalHours} hours`;
  }

  if (minutes > 0) {
    minutesStr = minutes === 1 ? '1 minute' : `${minutes} minutes`;
  }

  // Construct the result based on hours and minutes
  const parts = [hoursStr, minutesStr].filter(part => part !== '');
  return parts.length > 0 ? parts.join(' ') : '0 minutes';
}




function capitalizeFirstLetterOfEachWord(str) {
  if (!str) {
    return ''; // Return an empty string if input is null or undefined
  }

  return str
    .toString() // Convert to string if it's not already
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}


function Reports() {
  const [selectedDate, setSelectedDate] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [displayDate, setDisplayDate] = useState('');
  const [attendanceData, setAttendanceData] = useState([]);
  const [noRecordsMessage, setNoRecordsMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportType, setReportType] = useState('total-time');
  const [reportData, setReportData] = useState({ columns: [], rows: [] });
  const [selectedKid, setSelectedKid] = useState('');
  const [kids, setKids] = useState([]);
  const [currentStage, setCurrentStage] = useState(1);
  const [checkInError, setCheckInError] = useState('');
  const [kidName, setKidName] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const fetchKids = async () => {
    try {
      const response = await axios.get('https://attendance.flowerhousecrechecare.com.ng/php/fhcc-kids.php');
      const kidsData = response.data;

      if (Array.isArray(kidsData)) {
        const sortedKids = kidsData
          .filter(kid => kid.surname && kid.first_name)
          .sort((a, b) => {
            const nameA = `${a.surname} ${a.first_name}`.toLowerCase();
            const nameB = `${b.surname} ${b.first_name}`.toLowerCase();
            return nameA.localeCompare(nameB);
          });
        setKids(sortedKids);
      } else {
        throw new Error('Invalid data format');
      }
    } catch (error) {
      console.error('Error fetching kids:', error);
    }
  };


  useEffect(() => {
    let currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    currentDate = `${year}-${month}-${day}`;

    setSelectedDate(currentDate);
    setDisplayDate(formatDate(currentDate));
    fetchAttendanceData(currentDate);
    setFromDate(currentDate);
    setToDate(currentDate);
    fetchKids();
  }, []);


  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };


  // const handleUpdate = () => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setDisplayDate(formatDate(selectedDate));
  //     fetchAttendanceData(selectedDate)
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   }, 500);
  // };
  
  const handleUpdate = () => {
    setIsLoading(true);
    setTimeout(() => {
      setDisplayDate(formatDate(selectedDate));
      fetchAttendanceData(selectedDate)
        .then((data) => {
          console.log("Fetched Attendance Data:", data); 
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 500);
  };
  
  const handleGenerateReports = () => {
    setFromDate('');
    setToDate('');
    setReportType('total-time');
    setSelectedKid('');
    setReportData(null);
    setCurrentStage(1);
    setIsModalOpen(true);
  };

  const handleBackToSelection = () => {
    setCurrentStage(1);
  };

  const handleGenerateReport = async (event) => {
    event.preventDefault();

    setCheckInError('');

    if (!fromDate || !toDate) {
      setCheckInError('Dates cannot be empty');
      return;
    }
    if (new Date(fromDate) > new Date(toDate)) {
      setCheckInError('From Date should be earlier than To Date');
      return;
    }

    let endpoint = '';
    switch (reportType) {
      case 'total-time':
        endpoint = 'https://attendance.flowerhousecrechecare.com.ng/php/total-time-report.php';
        break;
      case 'overtime':
        endpoint = 'https://attendance.flowerhousecrechecare.com.ng/php/total-overtime-report.php';
        break;
      case 'attendance':
        endpoint = 'https://attendance.flowerhousecrechecare.com.ng/php/kid-attendance-report.php';
        break;
      default:
        console.error('Unknown report type');
        return;
    }

    const payload = {
      fromDate,
      toDate,
      kidId: selectedKid,
    };

    try {
      const response = await axios.post(endpoint, payload);

      if (response.data && Array.isArray(response.data.columns) && Array.isArray(response.data.rows)) {
        setReportData(response.data);
        setKidName(response.data.kidName);
        setCurrentStage(2);
      } else {
        console.error('Invalid report data format:', response.data);
      }
    } catch (error) {
      console.error('Error generating report:', error);
    }
  };

  const fetchAttendanceData = async (date) => {
    try {
      const response = await fetch(`https://attendance.flowerhousecrechecare.com.ng/php/fetch-attendance.php?selectedDate=${date}`);
      const data = await response.json();
  
      if (data.message) {
        setNoRecordsMessage(data.message);
        setAttendanceData([]);
      } else {
        setAttendanceData(data);
        setNoRecordsMessage('');
      }
      
      return data;
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      setNoRecordsMessage('An error occurred while fetching data.');
      setAttendanceData([]);
      return null; 
    }
  };
  

  return (
    <main>
      <div className="report-container">
        <div className='top-bar'>

          <div className="date-selection">
            <label htmlFor="selected-date">Select Date:</label>
            <input
              type="date"
              id="selected-date"
              name="selected-date"
              value={selectedDate}
              placeholder={selectedDate}
              onChange={handleDateChange}
            />
            <button className="updateBtn" onClick={handleUpdate}>Update</button>

          </div>
          <button className="generate-report-btn viewBtn reportsBtn" onClick={handleGenerateReports}>Generate New Report</button>

        </div>
        {isLoading && (
          <div className="spinner-overlay">
            <div className="spinner"></div>
          </div>
        )}
        <div className="report">
          {noRecordsMessage ? (
            <div className="no-records-message">
              <p>{noRecordsMessage}</p>
            </div>
          ) : (
            <table id="attendance-table">
              <thead>
                <tr>
                  <th colSpan="8">Daily Attendance Report</th>
                </tr>
                <tr>
                  <th colSpan="8">{displayDate}</th>
                </tr>
                <tr>
                  <th>#</th>
                  <th>Child's Name</th>
                  <th>Drop Off</th>
                  <th>Time In</th>
                  <th>Pick Up</th>
                  <th>Time Out</th>
                  <th>Total Time</th>
                  <th>Overtime</th>
                </tr>
              </thead>
              <tbody>
                {attendanceData.map((row, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.kid_name}</td>
                    <td>{capitalizeFirstLetterOfEachWord(row.checkin_by)}</td>
                    <td>{formatTime(row.time_in)}</td>
                    <td>{capitalizeFirstLetterOfEachWord(row.checkout_by)}</td>
                    <td>{formatTime(row.time_out, true, row.time_in)}</td>
                    <td>{row.time_out ? formatDuration(row.total_time) : '-'}</td>
                    <td>{row.time_out ? formatDuration(row.overtime) : '-'}</td>
                  </tr>
                ))}
              </tbody>

            </table>
          )}
          <div className="download-options">
            <button className="viewBtn reportsBtn" onClick={exportToExcel}>Export to Excel</button>
            <button className="viewBtn reportsBtn" onClick={exportToPDF}>Export to PDF</button>
          </div>
        </div>

        {isModalOpen && (
          <Modal
            title="Generate Report"
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleGenerateReport}
            submitButtonText="Generate"
            showCancel={false}
            showSubmitButton={currentStage === 1}
            className="reports-modal"
          >
            {currentStage === 1 ? (
              <>
                <div className="date-selection date-selection-2">
                  <label htmlFor="from-date">From Date:</label>
                  <input
                    type="date"
                    id="from-date"
                    name="from-date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
                <div className="date-selection date-selection-2">
                  <label htmlFor="to-date">To Date:</label>
                  <input
                    type="date"
                    id="to-date"
                    name="to-date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
                {checkInError && <p className="error-message">{checkInError}</p>}
                <div className="report-type-selection">
                  <label htmlFor="report-type">Select Report Type:</label>
                  <select id="report-type" name="report-type" value={reportType} onChange={(e) => setReportType(e.target.value)}>
                    <option value="total-time">Total Time Spent</option>
                    <option value="overtime">Total Overtime</option>
                    <option value="attendance">Kid Attendance Report</option>
                  </select>
                </div>
                {reportType === 'attendance' && (
                  <div className="kid-selection">
                    <label htmlFor="kid">Select Kid:</label>
                    <select id="kid" name="kid" required value={selectedKid} onChange={(e) => setSelectedKid(e.target.value)}>
                      <option value="">Select a kid</option>
                      {kids.map(kid => (
                        <option key={kid.kid_id} value={kid.kid_id}>
                          {`${kid.surname} ${kid.first_name}`}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </>
            ) : (
              <div className="report-display">
                <button onClick={handleBackToSelection} className='back-button'>Back</button>

                {reportData && reportData.columns && reportData.rows ? (
                  <table className="report-table" id="attendance-table">
                    <thead>
                      <tr>
                        <th
                          colSpan={(reportData.columns.length || 0) + 1}
                          style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}
                        >
                          {reportType === "total-time" && "Total Time Report"}
                          {reportType === "overtime" && "Overtime Report"}
                          {reportType === "attendance" && `Attendance Report: ${kidName}`}
                        </th>
                      </tr>
                      <tr>
                        <th
                          colSpan={(reportData.columns.length || 0) + 1}
                          style={{ textAlign: "center", fontWeight: "bold", fontSize: "14px" }}
                        >
                          {formatDate(fromDate)} - {formatDate(toDate)}
                        </th>
                      </tr>
                      <tr>
                        <th>#</th>
                        {(reportData.columns || []).map((col, index) => (
                          <th key={index}>{col}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {(reportData.rows || []).map((row, index) => {
                        // Check if it's the last row and the report type is 'attendance'
                        const isLastRow = index === reportData.rows.length - 1;
                        const excludeNumbering = reportType === "attendance" && isLastRow;

                        return (
                          <tr
                            key={index}
                            className={reportType === "attendance" && isLastRow ? 'last-row-class' : ''}
                          >
                            <td>{!excludeNumbering ? index + 1 : ''}</td>
                            {(row || []).map((cell, cellIndex) => (
                              <td key={cellIndex}>{cell}</td>
                            ))}
                          </tr>
                        );
                      })}

                    </tbody>
                  </table>
                ) : (
                  <p className="nokids-error">No report data available for this period.</p>
                )}



                <div className="download-options">
                  <button className="viewBtn reportsBtn" onClick={exportReportToExcel}>Export to Excel</button>
                  <button className="viewBtn reportsBtn" onClick={exportReportToPDF}>Export to PDF</button>
                </div>
              </div>
            )}
          </Modal>
        )}



      </div>
    </main>
  );
}

export default Reports;
